import { motion } from 'framer-motion';
import { rem } from 'polished';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import theme from '../../theme';

export const SECTION_TITLE_MARGIN_BOTTOM = [rem(140), rem(240)];

function getWidth(width) {
	return typeof width === 'number' ? rem(width) : width;
}

function getImageProperty(width, property = 'width') {
	if (!width) return;
	if (typeof width === 'number' || typeof width === 'string') {
		return `${property}: ${getWidth(width)};`;
	}
	if (width.length >= 1) {
		const items = width.reduce((acc, cv, idx) => {
			if (idx === 0) {
				acc.push(`${property}: ${getWidth(width[idx])};`);
			} else {
				acc.push(`
          @media screen and (min-width: ${theme.breakpoints[idx - 1]}) {
            ${property}: ${getWidth(width[idx])};
          }
        `);
			}
			return acc;
		}, []);
		return items.join('');
	}
}

export const Section = styled(Flex)`
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-right: auto !important;
	margin-left: auto !important;
	overflow: visible;
	position: relative;

	@media (min-width: 1024px) {
		max-width: 980px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;

export const ValueSectionContainer = styled(Flex)`
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-right: auto !important;
	margin-left: auto !important;
	overflow: visible;
	position: relative;

	@media (min-width: 1024px) {
		max-width: 980px;
	}
	@media (min-width: 1200px) {
		max-width: 1200px;
	}
`;

export const ValueSectionWrapper = styled('div')`
	position: relative;
`;

export const ContentContainer = styled(motion.div)`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: auto 1fr;
	column-gap: 20px;
	z-index: 10000000000000;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		grid-template-rows: 1fr;
	}
`;

export const TextContent = styled(Box)`
	grid-column: 1 / 10;
	grid-row: 1 / 2;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		grid-row: 1 / 2;
		grid-column: 9 / 13;
		align-self: center;
	}
`;

export const ImgContent = styled(Flex)`
	grid-column: 1 / 13;
	grid-row: 2 / 3;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		grid-row: 1 / 2;
		grid-column: 1 / 9;
	}
`;

export const TextContentReverse = styled(Box)`
	grid-column: 1 / 10;
	grid-row: 1 / 2;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		grid-row: 1 / 2;
		grid-column: 1 / 4;
		align-self: center;
	}
`;
export const ImgContentReverse = styled(Flex)`
	grid-column: 1 / 13;
	grid-row: 2 / 3;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		grid-row: 1 / 2;
		grid-column: 4 / 13;
	}
`;

export const MainImage = styled(motion.img)`
	max-width: 260px;
	// @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
	// 	max-width: 290px;
	// }
`;

export const ImageContainer = styled('div')`
	${({ width }) => getImageProperty(width)}
	${({ maxWidth }) => getImageProperty(maxWidth, 'maxWidth')}
	> div {
		position: unset !important;
	}
	.image {
		object-fit: contain;
		width: 100% !important;
		position: relative !important;
		height: unset !important;
	}
`;

export const ValueImageContainer = styled(motion.div)`
	position: relative;

	.hidden {
		@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
			visibility: hidden;
		}
	}
`;

export const SwoopImage = ({ src, width = '100%', maxWidth, imgProps, ...props }) => {
	return (
		<ImageContainer width={width} maxWidth={maxWidth} {...props}>
			<img className="image" src={src} layout="fill" {...imgProps} />
		</ImageContainer>
	);
};

export const ElevateSmallImg = styled(motion.div)`
	position: absolute;
	right: ${rem(-40)};
	top: ${rem(-40)};
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		right: ${rem(-180)};
		top: ${rem(120)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		left: ${rem(-220)};
		top: ${rem(-120)};
	}
`;

export const ElevateBigImg = styled(motion.div)`
	position: absolute;
	bottom: 0;
	left: ${rem(-40)};
	bottom: ${rem(-60)};
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		bottom: ${rem(-100)};
		left: ${rem(-120)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		bottom: ${rem(80)};
		left: ${rem(-100)};
	}
`;
