import { motion } from 'framer-motion';
import { rem } from 'polished';
import styled from 'styled-components';
import { space } from 'styled-system';

export const PageSectionContainer = styled('div')`
	width: 100%;
	position: relative;
	overflow: hidden;
	padding-top: 250px;
	margin-top: -250px;
`;

export const BoxPageSectionContainer = styled(PageSectionContainer)`
	${({ hasOverflowY }) =>
		!hasOverflowY &&
		`
		padding-top: 0;
		margin-top: 0;
	`}
	overflow-y: visible;
	overflow-x: hidden;
	${space}
`;

export const MotionDivWithSpacing = styled(motion.div)(space);

export const Circle1 = styled('div')`
	position: absolute;
	right: ${rem(-400)};
	bottom: ${rem(-100)};
	height: ${rem(574)};
	width: ${rem(574)};
	border: 0.5px solid ${({ theme }) => theme.colors.calcite};
	border-radius: 50%;
	display: none;
	z-index: 0;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		right: ${rem(-180)};
		display: block;
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		right: ${rem(-180)};
		bottom: ${rem(60)};
		display: block;
	}
`;
export const Circle2 = styled('div')`
	position: absolute;
	left: ${rem(-220)};
	bottom: 0;
	height: ${rem(470)};
	width: ${rem(470)};
	border: 0.5px solid ${({ theme }) => theme.colors.calcite};
	border-radius: 50%;
	display: none;

	&::before {
		content: '';
		display: block;
		position: absolute;
		bottom: ${rem(-70)};
		right: ${rem(70)};
		height: ${rem(120)};
		width: ${rem(120)};
		border: 0.5px solid ${({ theme }) => theme.colors.calcite};
		border-radius: 50%;
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		display: block;
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		display: block;
		top: ${rem(150)};
	}
`;

export const Circle3 = styled('div')`
	position: absolute;
	left: ${rem(-30)};
	bottom: ${rem(-260)};
	height: ${rem(180)};
	width: ${rem(180)};
	border: 0.5px solid ${({ theme }) => theme.colors.calcite};
	border-radius: 50%;
	display: none;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		display: block;
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		bottom: ${rem(40)};
	}
`;

export const ValueSectionFloatingImageContainer = styled('div')`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;

	${space}
`;

export const ValueSectionFloatingImage = styled('div')`
	width: 240px;
	position: sticky;
	align-self: flex-start;
	z-index: 1;
	display: none;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		width: 320px;
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		display: block;
		width: 320px;
		margin-left: -80px;
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
		width: 320px;
		margin-left: -100px;
	}
`;

export const BackgroundImage = styled(motion.div)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: url(${({ src }) => src});
	background-position: contain;
	background-size: cover;
	zindex: -1;
`;
