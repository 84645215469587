import { rem } from 'polished';
import React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

const ContentWrapper = styled('div')`
	transform: translate3d(0, 0, 0);
	flex: 0 0 100%;
	min-width: 0;
	padding-left: ${rem(16)};
`;

const ContentCard = ({ title, subtitle, body }) => {
	return (
		<ContentWrapper flexDirection="column">
			<Box marginBottom={rem(34)}>
				<h2 className="h3">{title}</h2>
			</Box>
			<Box marginBottom={rem(34)}>
				<h2 className="h4">{subtitle}</h2>
			</Box>
			<Box>{body}</Box>
		</ContentWrapper>
	);
};

export default ContentCard;
