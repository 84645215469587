import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rem } from 'polished';
export const NewSpiritIcon = styled(motion.img)`
	position: absolute;
	top: ${rem(-50)};
	left: ${rem(-60)};
	max-width: ${rem(130)};
	z-index: 5;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		top: ${rem(-80)};
		left: ${rem(-120)};
		max-width: ${rem(190)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		right: ${rem(60)};
		top: ${rem(-40)};
		max-width: ${rem(180)};
	}
`;
export const NewWrapIcon = styled(motion.img)`
	position: absolute;
	top: ${rem(120)};
	right: ${rem(-65)};
	max-width: ${rem(130)};
	z-index: 1;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		right: ${rem(-140)};
		top: ${rem(140)};
		max-width: ${rem(190)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		right: ${rem(80)};
		top: ${rem(200)};
		max-width: ${rem(180)};
	}
`;
export const FairwayPicture = styled(motion.img)`
	position: absolute;
	bottom: ${rem(-60)};
	left: ${rem(-34)};
	max-height: ${rem(270)};
	z-index: 0;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		max-height: ${rem(420)};
		left: ${rem(-120)};
		// bottom: ${rem(-310)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		max-height: ${rem(380)};
		left: ${rem(-190)};
		bottom: ${rem(-30)};
	}
`;
