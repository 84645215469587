const cardData = [
	{
		title: 'Jen Clyde',
		location: 'ForeAll',
		tag: '@jenstyles',
		comment: `Co-Founder + CEO-Fore All Women's Golf Club Founder + CEO-TheGivBox`,
		instagram: 'https://www.instagram.com/jenstyles/?hl=en',
		twitter: 'https://twitter.com/golf_fore_all',
		linkedIn: 'https://www.linkedin.com/in/jen-clyde/',
		imgSrc: '/swoop/ambassadors/jen.jpeg',
	},
	{
		title: 'Hannah B. Leiner',
		location: 'Golf Influencer',
		tag: '@hannahbleiner',
		comment: `Golf enthusiast and PxG Ambassador`,
		instagram: 'https://www.instagram.com/hannahbleiner/?hl=en',
		twitter:
			'https://twitter.com/hannahbleiner?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
		linkedIn: '',
		imgSrc: '/swoop/ambassadors/hannah.jpeg',
	},
	{
		title: 'Suzi Stanley',
		location: 'Avid Golfer',
		tag: '@suzigolf',
		comment: `Professional Long Drive Athlete`,
		instagram: 'https://www.instagram.com/suzigolf/?hl=en',
		twitter: 'https://twitter.com/SuziGolf',
		linkedIn: '',
		imgSrc: '/swoop/ambassadors/suzi.jpeg',
	},
	{
		title: 'Olajuwon Ajanaku',
		location: 'Eastside Golf',
		tag: '@oooolajuwon',
		comment: `Professional golfer and founder of Eastside Golf,  a lifestyle golf brand developed to raise awareness about golf among youth and non golfers`,
		instagram: 'https://www.instagram.com/oooolajuwon/?hl=en',
		twitter: 'https://twitter.com/olajuwon',
		linkedIn: 'https://www.linkedin.com/in/olajuwon-ajanaku-5011811a/',
		imgSrc: '/swoop/ambassadors/olajuwon.jpeg',
	},
];

export default cardData;
