import { rem } from 'polished';
import styled from 'styled-components';
import { SwoopImage } from '../../../common/sections';

export const BoostRevenueSandwich = styled(SwoopImage)`
	position: absolute;
	top: ${rem(230)};
	left: ${rem(-70)};
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		left: unset;
		right: ${rem(-130)};
		top: ${rem(200)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		right: unset;
		left: ${rem(20)};
		top: ${rem(100)};
	}
`;

export const BoostRevenueAddToCart = styled(SwoopImage)`
	position: absolute;
	bottom: ${rem(-100)};
	left: ${rem(-40)};
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		left: ${rem(-180)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		left: ${rem(-200)};
		top: ${rem(-40)};
	}
`;

export const BoostRevenueLemonade = styled(SwoopImage)`
	position: absolute;
	top: ${rem(-40)};
	right: ${rem(-40)};
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		left: ${rem(-110)};
		top: ${rem(-50)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		left: ${rem(-180)};
		top: unset;
		bottom: ${rem(0)};
	}
`;
