import { rem } from 'polished';
import React, { useRef } from 'react';
import styled from 'styled-components';
import {
	ContentContainer,
	ImgContent,
	MainImage,
	TextContent,
	ValueImageContainer,
	ValueSectionContainer,
	ValueSectionWrapper,
	SECTION_TITLE_MARGIN_BOTTOM,
	SwoopImage,
	ElevateBigImg,
} from '../../../common/sections';

const EnhanceBottle = styled(SwoopImage)`
	position: absolute;
	top: ${rem(-80)};
	right: ${rem(-40)};
	transform: rotate(15deg);

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		top: ${rem(-150)};
		right: ${rem(-80)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		right: unset;
		top: ${rem(-200)};
		left: ${rem(-40)};
	}
`;
const EnhanceTee1 = styled(SwoopImage)`
	position: absolute;
	bottom: ${rem(-40)};
	right: ${rem(-40)};
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		display: block;
		top: ${rem(-120)};
		left: ${rem(-80)};
		bottom: unset;
		right: unset;
	}
`;
const EnhanceTee2 = styled(SwoopImage)`
	position: absolute;
	bottom: ${rem(0)};
	left: ${rem(90)};
	transform: rotate(70deg);
	display: none;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		max-height: ${rem(540)};
		display: block;
	}
`;

const FollowThruWrapper = styled(ElevateBigImg)`
	bottom: ${rem(-40)};
	left: ${rem(-40)};

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		left: ${rem(-100)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		left: ${rem(-160)};
	}
`;

export const EnhanceSection = ({
	anchorRef = null,
	onEnter = () => {},
	topOffset = '0',
	children = null,
	title = '',
	body = '',
	src = '/elements/hello-john.png',
	...rest
}) => {
	const DEFAULT_REF = useRef(null);
	return (
		<ValueSectionWrapper>
			<ValueSectionContainer {...rest} position="relative" flexDirection="column" mt={[]}>
				<ContentContainer
					initial="hidden"
					whileInView="visible"
					viewport={{ once: false }}
					transition={{ duration: 1.5 }}
					variants={{
						visible: { opacity: 1 },
						hidden: { opacity: 0 },
					}}
				>
					<TextContent flex={[1, 1]} mb={SECTION_TITLE_MARGIN_BOTTOM}>
						<h3 className="mb-5">{title}</h3>
						<p>{body}</p>
					</TextContent>
					<ImgContent flex={[1, 'auto']} justifyContent="center">
						<ValueImageContainer>
							<MainImage
								className="hidden"
								ref={anchorRef || DEFAULT_REF}
								src={src}
								alt=""
							/>
							<EnhanceBottle
								width={[90, 130]}
								src="/wp-content/themes/theme-kit/images/swoop/courses/course-huerca.png"
							/>
							<EnhanceTee1
								width={[100]}
								src="/wp-content/themes/theme-kit/images/swoop/courses/course-tee.png"
							/>
							<EnhanceTee2
								width={[140]}
								src="/wp-content/themes/theme-kit/images/swoop/courses/course-tee.png"
							/>
							<FollowThruWrapper>
								<SwoopImage
									width={[95, 140, 170]}
									src="/wp-content/themes/theme-kit/images/swoop/courses/courses-follow-thru.png"
								/>
							</FollowThruWrapper>
						</ValueImageContainer>
					</ImgContent>
				</ContentContainer>
			</ValueSectionContainer>
			{children}
		</ValueSectionWrapper>
	);
};
