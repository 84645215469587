import Fade from 'embla-carousel-fade';
import React, { useEffect, useCallback } from 'react';
import { useCustomCarousel } from '../../hooks/useCustomCarousel';
import CarouselCard from './carousel-card';
import ContentCard from './content-card';

import {
	CarouselControlContainer,
	CarouselWrapper,
	EmblaContentContainer,
	EmblaContentParent,
	EmblaImageContainer,
	EmblaImageParent,
	LeftControl,
	RightControl,
} from './styled';

export const CarouselWithText = ({ slides = [] }) => {
	const {
		scrollPrev: scrollPrevContent,
		scrollNext: scrollNextContent,
		emblaRef: emblaRefContent,
		emblaApi: emblaApiContent,
	} = useCustomCarousel({
		loop: false,
		align: 'center',
		containScroll: false,
		plugins: [Fade()],
	});
	const {
		scrollPrev: scrollPrevSlide,
		scrollNext: scrollNextSlide,
		emblaRef: emblaRefSlides,
		emblaApi: emblaApiSlides,
	} = useCustomCarousel({
		loop: false,
	});

	const onSettle = useCallback(() => {
		if (emblaApiSlides && emblaApiContent) {
			const selectedIndex = emblaApiSlides.selectedScrollSnap();
			emblaApiContent.scrollTo(selectedIndex, true);
		}
	}, [emblaApiSlides, emblaApiContent]);

	useEffect(() => {
		if (!emblaApiSlides) return;
		emblaApiSlides.on('settle', onSettle);
		return () => {
			emblaApiSlides.off('settle', onSettle);
		};
	}, [onSettle, emblaApiSlides]);

	const isDisplayingMultipleCards = slides && slides?.length > 1;

	return (
		<>
			<CarouselWrapper
				className={`testimonials-carousel${
					!isDisplayingMultipleCards ? ' testimonials-carousel--single' : ''
				}`}
			>
				<EmblaContentParent flexDirection="row" ref={emblaRefContent}>
					<EmblaContentContainer>
						{slides.map((slide, idx) => {
							return <ContentCard key={`carousel-with-text-card-${idx}`} {...slide} />;
						})}
					</EmblaContentContainer>
				</EmblaContentParent>
				<EmblaImageParent ref={emblaRefSlides}>
					<EmblaImageContainer className="testimonials-carousel__embla-container">
						{slides.map((item, idx) => {
							return (
								<CarouselCard
									className="testimonials-carousel__card"
									key={`idx-${idx}`}
									{...item}
								/>
							);
						})}
					</EmblaImageContainer>
				</EmblaImageParent>
				{isDisplayingMultipleCards && (
					<CarouselControlContainer mb={[5]}>
						<LeftControl
							className="testimonials-carousel__control"
							onClick={() => {
								scrollPrevContent();
								scrollPrevSlide();
							}}
						/>
						<RightControl
							className="testimonials-carousel__control"
							onClick={() => {
								scrollNextContent();
								scrollNextSlide();
							}}
						/>
					</CarouselControlContainer>
				)}
			</CarouselWrapper>
		</>
	);
};

export default CarouselWithText;
