import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

export const Section = styled(Flex)`
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-right: auto !important;
	margin-left: auto !important;
	overflow: visible;
	position: relative;
	@media (min-width: 1024px) {
		max-width: 980px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;

export const CarouselWrapper = styled(Box)`
	overflow-x: hidden;
`;

export const PhotoCarousel = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	-webkit-overflow-scrolling: touch;
`;

export const ContentWrapper = styled('div')``;

export const Embla = styled('div')`
	position: relative;
	padding: 20px 0;
	margin-left: auto;
	margin-right: auto;
`;

export const EmblaViewport = styled('div')`
	overflow: hidden;
	width: 100%;

	.is-draggable {
		cursor: move;
		cursor: grab;
	}

	.is-dragging {
		cursor: grabbing;
	}
`;

export const EmblaContainer = styled('div')`
	display: flex;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: transparent;
	gap: 10px;
`;

export const EmblaSlide = styled('div')`
	position: relative;
	min-width: 88%;
	@media screen and (min-width: 768px) {
		min-width: 44%;
	}
	@media screen and (min-width: 1024px) {
		min-width: 33%;
	}
`;

export const EmblaSlideInner = styled('div')`
	position: relative;
	overflow: hidden;
	height: 420px;
`;

export const EmblaSlideImg = styled('img')`
	position: absolute;
	display: block;
	top: 50%;
	left: 50%;
	width: auto;
	min-height: 100%;
	min-width: 100%;
	max-width: 500px;
	transform: translate(-50%, -50%);
	object-fit: cover;
`;

export const ContentTitle = styled('h4')`
	max-width: 580px;
	margin-bottom: 20px;
`;
export const ContentBody = styled('p')`
	max-width: 580px;
`;
