import { rem } from 'polished';
import React, { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { ThemeProvider } from 'styled-components';
import theme from '../../theme';
import Text from '../common/text';
import { useForm } from '@formspree/react';

import { MenuButton } from './menu-button';

import {
	ContactFormContainer,
	ContactFormWrapper,
	DualSection,
	FormInput,
	FormInputWrapper,
	FormTextArea,
} from './styled';

import { DropdownMenu } from './dropdown-menu';

let initialState = {
	value: 'inquiry',
	label: 'Inquiry',
};

const ContactForm = ({ title, subtitle, body, options }) => {
	const [selectedOption, setSelectedOption] = useState(initialState);
	const [_, handleSubmit] = useForm('maykpnrl');
	const [contactForm, setContactForm] = useState(null);
	return (
		<ThemeProvider theme={theme}>
			<ContactFormContainer className="contact-form-container">
				<DualSection className="dual-section">
					<Flex className="contact-header" flexDirection="column" pr={[, , 7]}>
						<Text
							variant="h1"
							color="sage"
							fontWeight="bold"
							fontFamily={theme.typography.fonts.tertiary}
							mb={[5]}
						>
							{title}
						</Text>
						<Text variant="h2" color="sage" mb={[4]}>
							{subtitle}
						</Text>
						<Text
							variant="h4"
							color="sage"
							fontSize={[`${rem(15)} !important`]}
							lineHeight={[rem(20), rem(24)]}
						>
							{body}
						</Text>
					</Flex>
					<Flex alignItems="flex-end">
						<ContactFormWrapper
							className="contact-form-wrapper"
							ref={setContactForm}
							onSubmit={handleSubmit}
						>
							<FormInputWrapper className="form-input-wrapper first">
								<FormInput
									type="text"
									variant="h4"
									px={4}
									py={3}
									name="name"
									placeholder="Name"
									maxWidth={['100%']}
									onFocus={(e) => (e.target.placeholder = '')}
									onBlur={(e) => (e.target.placeholder = 'Name')}
								/>
							</FormInputWrapper>
							<FormInputWrapper className="form-input-wrapper second">
								<FormInput
									type="text"
									variant="h4"
									px={4}
									py={3}
									name="email"
									placeholder="Email"
									maxWidth={['100%']}
									onFocus={(e) => (e.target.placeholder = '')}
									onBlur={(e) => (e.target.placeholder = 'Email')}
								/>
							</FormInputWrapper>
							<FormInputWrapper className="form-input-wrapper third">
								<FormInput
									type="text"
									variant="h4"
									px={4}
									py={3}
									name="phone"
									placeholder="Phone"
									maxWidth={['100%']}
									onFocus={(e) => (e.target.placeholder = '')}
									onBlur={(e) => (e.target.placeholder = 'Phone')}
								/>
							</FormInputWrapper>
							<Box position="relative" width="100%" className="form-input-wrapper fourth">
								<FormInputWrapper>
									<DropdownMenu
										variant="h4"
										px={4}
										py={3}
										selectedOption={selectedOption}
										setSelectedOption={setSelectedOption}
										placeholder="Inquiry"
										maxWidth={['100%']}
									/>
								</FormInputWrapper>
							</Box>
							<FormInputWrapper className="form-input-wrapper fifth">
								<FormTextArea
									variant="h4"
									rows={5}
									px={4}
									py={3}
									name="message"
									placeholder="Message"
									maxWidth={['100%']}
									onFocus={(e) => (e.target.placeholder = '')}
									onBlur={(e) => (e.target.placeholder = 'Message')}
								/>
							</FormInputWrapper>
							<FormInputWrapper className="form-input-wrapper sixth">
								<MenuButton
									onClick={() => {
										contactForm.dispatchEvent(
											new Event('submit', { bubbles: true, cancelable: true })
										);
									}}
									borderColor={theme.colors.orange}
									color={theme.colors.orange}
								>
									Submit
								</MenuButton>
							</FormInputWrapper>
						</ContactFormWrapper>
					</Flex>
				</DualSection>
			</ContactFormContainer>
		</ThemeProvider>
	);
};

export default ContactForm;
