import React from 'react';
import styled from 'styled-components';

const LinkBase = ({ children, href, ...rest }) => {
	return (
		<a href={href} {...rest}>
			{children}
		</a>
	);
};

const Link = styled(LinkBase)`
	color: ${({ theme }) => theme.colors.orange};
	text-decoration: none;
	font-weight: 500;
	transition: color 0.3s ease;
	&:hover {
		color: ${({ theme }) => theme.colors.darkOrange};
	}
`;

export default Link;
