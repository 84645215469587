import { rem } from 'polished';

const theme = {
	logos: {},
	type: 'dark',
	typography: {
		fonts: {
			primary: "'Zen Antique', serif",
			secondary: "'Shippori Antique', sans-serif",
			tertiary: "'JetBrains Mono', monospace",
		},
	},
	colors: {
		orange: '#FF8B00',
		brown: '#D8D8D8',
		black: '#252525',
		white: '#ffffff',
		calcite: '#E7E2DA',
		sage: '#1B1814',
		lightGray: '#989898',
		slate: '#505050',
		darkGray: 'rgba(152, 152, 152, .25)',
	},
	space: [0, 4, 8, 16, 20, 32, 64, 128, 256],
	breakpoints: [rem(768), rem(1024), rem(1440)],
};

theme['space'].xs = theme.space[1];
theme['space'].sm = theme.space[2];
theme['space'].md = theme.space[3];
theme['space'].lg = theme.space[4];
theme['space'].xl = theme.space[5];
theme['space'].xxl = theme.space[6];
theme['space'].xxxl = theme.space[7];
theme['space'].xxxxl = theme.space[8];

export default theme;
