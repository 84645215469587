import { rem } from 'polished';
import React, { useRef } from 'react';
import { Waypoint } from 'react-waypoint';
import { AnimatePresence } from 'framer-motion';

import { SandwichImg, ModeloImg } from './styled';

import {
	ImgContentReverse,
	MainImage,
	TextContentReverse,
	ValueImageContainer,
	ValueSectionContainer,
	ValueSectionWrapper,
	ContentContainer,
} from '../../../common/sections';

import { SECTION_TITLE_MARGIN_BOTTOM } from '../../../common/sections';

export const IncreaseMarginsSection = ({
	anchorRef = null,
	onEnter = () => {},
	topOffset = '0px',
	bottomOffset = '0px',
	children = null,
	debug = false,
	title = '',
	body = '',
	src = '/elements/trilogy-screen.png',
	...rest
}) => {
	return (
		<ValueSectionWrapper>
			<ValueSectionContainer {...rest} position="relative" flexDirection="column">
				<ContentContainer
					initial="hidden"
					whileInView="visible"
					viewport={{ once: false }}
					transition={{ duration: 1.5 }}
					variants={{
						visible: { opacity: 1 },
						hidden: { opacity: 0 },
					}}
				>
					<AnimatePresence>
						<>
							<TextContentReverse mb={SECTION_TITLE_MARGIN_BOTTOM}>
								<h3 className="mb-5">{title}</h3>
								<p>{body}</p>
							</TextContentReverse>
							<ImgContentReverse justifyContent={['center', 'center', 'flex-end']}>
								<ValueImageContainer>
									<MainImage className="hidden" src={src} alt="" ref={anchorRef} />
									<SandwichImg
										src="/wp-content/themes/theme-kit/images/swoop/courses/courses-sandwich.png"
										alt="golfer holding whiskey drink"
									/>
									<ModeloImg
										src="/wp-content/themes/theme-kit/images/swoop/courses/courses-kiosk.png"
										alt="golfer holding whiskey drink"
									/>
								</ValueImageContainer>
							</ImgContentReverse>
						</>
					</AnimatePresence>
				</ContentContainer>
				<Waypoint
					onEnter={onEnter}
					debug={debug}
					topOffset={topOffset}
					bottomOffset={bottomOffset}
				/>
			</ValueSectionContainer>
			{children}
		</ValueSectionWrapper>
	);
};
