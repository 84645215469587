import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useAnimationControls } from 'framer-motion';
import { useViewport } from 'use-viewport';
import { useOnScreen } from '../../hooks/useOnScreen';
import theme from '../../theme';
import { BoostRevenueSection } from './sections/boost-revenue';
import { IncreaseMarginsSection } from './sections/increase-margins';
import { EnhanceSection } from './sections/enhance-experiences';
import { IntegrateSection } from './sections/integrate';

import {
	ValueSectionFloatingImageContainer,
	ValueSectionFloatingImage,
	BackgroundImage,
	MotionDivWithSpacing,
	BoxPageSectionContainer,
	Circle1,
	Circle2,
	Circle3,
} from './styled';
import { ThemeProvider } from 'styled-components';

export default function ScrollableStoryBoard() {
	const controls = useAnimationControls();
	const floatingImageRef = useRef(null);
	const firstImageRef = useRef(null);
	const floatingImageAnchorStartRef = useRef(null);
	const floatingImageAnchorFinalRef = useRef(null);
	const floatingImageContainerFinalRef = useRef(null);
	const firstImageContainerRef = useRef(null);
	const { height } = useViewport();
	const [, setStickyImgPadding] = useState(0);
	const [imageSrc, setImageSrc] = useState(
		'/wp-content/themes/theme-kit/images/swoop/courses/courses-sandwich-screen.png'
	);
	const [topOffset, setTopOffset] = useState(0);
	const [, setBottomOffset] = useState(0);

	const [firstSection, firstSectionIsVisible] = useOnScreen({
		threshold: 0.6,
	});
	const [secondSection, secondSectionIsVisible] = useOnScreen({
		threshold: 0.6,
	});
	const [thirdSection, thirdSectionIsVisible] = useOnScreen({
		threshold: 0.6,
	});
	const [fourthSection, fourthSectionIsVisible] = useOnScreen({
		threshold: 0.6,
	});

	useEffect(() => {
		if (firstSectionIsVisible) {
			(async () => {
				await controls.start({ opacity: 0, transition: { duration: 0.5 } });
				await setImageSrc(
					'/wp-content/themes/theme-kit/images/swoop/courses/courses-sandwich-screen.png'
				);
				await controls.start({ opacity: 1, transition: { duration: 0.5 } });
			})();
		}
	}, [firstSectionIsVisible]);
	useEffect(() => {
		if (secondSectionIsVisible) {
			(async () => {
				await controls.start({ opacity: 0 });
				await setImageSrc(
					'/wp-content/themes/theme-kit/images/swoop/courses/courses-turkey-club.png'
				);
				await controls.start({ opacity: 1 });
			})();
		}
	}, [secondSectionIsVisible]);
	useEffect(() => {
		if (thirdSectionIsVisible) {
			(async () => {
				await controls.start({ opacity: 0 });
				setImageSrc(
					'/wp-content/themes/theme-kit/images/swoop/courses/courses-black-screen.png'
				);
				await controls.start({ opacity: 1 });
			})();
		}
	}, [thirdSectionIsVisible]);
	useEffect(() => {
		if (fourthSectionIsVisible) {
			(async () => {
				await controls.start({ opacity: 0 });
				await setImageSrc(
					'/wp-content/themes/theme-kit/images/swoop/golfers/golfers-phone-4.png'
				);
				await controls.start({ opacity: 1 });
			})();
		}
	}, [fourthSectionIsVisible]);

	useLayoutEffect(() => {
		const containerPos = firstImageContainerRef.current.getBoundingClientRect().y;
		const imagePos = firstImageRef.current.getBoundingClientRect().y;
		const deltaForPadding = Math.abs(containerPos - imagePos);
		setStickyImgPadding(deltaForPadding);
	}, [
		floatingImageAnchorStartRef.current,
		firstImageRef.current,
		firstImageContainerRef.current,
	]);

	useEffect(() => {
		const imagePos = floatingImageAnchorFinalRef.current.getBoundingClientRect().bottom;
		const containerPos =
			floatingImageContainerFinalRef.current.getBoundingClientRect().bottom;
		const deltaForBottom = Math.abs(imagePos - containerPos);
		setBottomOffset(deltaForBottom);
	}, [floatingImageAnchorFinalRef.current, floatingImageContainerFinalRef.current]);

	useEffect(() => {
		setTimeout(() => {
			const top = (height - floatingImageRef.current.clientHeight) / 2;
			setTopOffset(top);
		}, 500);
	}, [height, floatingImageRef.current, setTopOffset]);

	return (
		<ThemeProvider theme={theme}>
			<div className="ValueSections" style={{ position: 'relative' }}>
				<ValueSectionFloatingImageContainer mb={[, 8]}>
					<ValueSectionFloatingImage ref={floatingImageRef} style={{ top: topOffset }}>
						<BackgroundImage src={imageSrc} animate={controls} />
						<img
							src="/wp-content/themes/theme-kit/images/elements/blank-iphone.png"
							alt=""
							style={{ width: '100%', zIndex: 100000 }}
						/>
					</ValueSectionFloatingImage>
				</ValueSectionFloatingImageContainer>
				<BoxPageSectionContainer hasOverflowY={false}>
					<MotionDivWithSpacing ref={firstSection} mb={[6, 7, 8]}>
						<BoostRevenueSection
							mt={[8]}
							containerRef={firstImageContainerRef}
							anchorRef={firstImageRef}
							title="BOOST REVENUE"
							body="Monetize every food and beverage impulse by allowing customers to order more frequently and conveniently."
							src="/wp-content/themes/theme-kit/images/swoop/courses/courses-sandwich-screen.png"
						>
							<Circle1 />
						</BoostRevenueSection>
					</MotionDivWithSpacing>
					<MotionDivWithSpacing ref={secondSection} mb={[6, 7, 8]}>
						<IncreaseMarginsSection
							pt={[8]}
							title="INCREASE MARGINS"
							body="Enjoy all of the culinary comforts for the club grill, on the greens."
							src="/wp-content/themes/theme-kit/images/swoop/courses/courses-turkey-club.png"
						>
							<Circle2 />
						</IncreaseMarginsSection>
					</MotionDivWithSpacing>

					<MotionDivWithSpacing ref={thirdSection} mb={[6, 7, 8]}>
						<EnhanceSection
							title="ENHANCE EXPERIENCES"
							body="Create loyal guests and out-gain competitors by offering a superior on-course food & beverage experience."
							pt={[8]}
							src="/wp-content/themes/theme-kit/images/swoop/courses/courses-black-screen.png"
						>
							<Circle1 />
							<Circle3 />
						</EnhanceSection>
					</MotionDivWithSpacing>
					<MotionDivWithSpacing ref={fourthSection} mb={[7, 8, 7]}>
						<IntegrateSection
							pt={[8]}
							title="INTEGRATE SEAMLESSLY"
							body="Realize the benefits of on-course purchase without additional overhead and without setup complexity."
							containerRef={floatingImageContainerFinalRef}
							anchorRef={floatingImageAnchorFinalRef}
							src="/wp-content/themes/theme-kit/images/swoop/courses/courses-sandwich-screen.png"
						/>
					</MotionDivWithSpacing>
				</BoxPageSectionContainer>
			</div>
		</ThemeProvider>
	);
}
