import { useForm } from '@formspree/react';
import Text from '../common/text';
import React, { useRef } from 'react';
import { Flex } from 'rebass/styled-components';
import { ThemeProvider } from 'styled-components';
import theme from '../../theme';
import { MenuButton } from '../contact-form/menu-button';

import {
	NewsletterWrapper,
	ImageWrapper,
	ContentWrapper,
	CustomInput,
	SubmitButton,
} from './styled';

export const NewsletterSection = ({
	image = '/elements/burger.png',
	title = 'KEEP UP.',
	body = `Join the Swoop scoop. Get the latest news, find events, and receive deals`,
	hasCTA = false,
	cta = {},
	...rest
}) => {
	const [state, handleSubmit] = useForm('xqkjyarw');
	const formRef = useRef(null);
	const textProps = {
		color: theme.colors.white,
		variant: 'link1',
		textAlign: rest.textAlign || 'left',
		mr: rest.mr,
	};
	return (
		<ThemeProvider theme={theme}>
			<NewsletterWrapper mb={[6]} ref={formRef} {...rest} onSubmit={handleSubmit}>
				<ImageWrapper src={image}>
					<img src={image} alt="Newsletter Profile Image" />
				</ImageWrapper>
				<ContentWrapper py={6} px={[4, 6, 7]} flexDirection={['column']}>
					<Flex mb={[5]} flexDirection={['column']}>
						<Text variant="h1" mb={[4]} color="white">
							{title}
						</Text>
						<Text color="white" fontWeight="bold" maxWidth={[, '60%']}>
							{body}
						</Text>
					</Flex>
					{!hasCTA && (
						<Flex flexDirection={['column', 'row']}>
							<CustomInput
								color={theme.colors.white}
								type="text"
								name="email"
								placeholder="Email Address"
							/>
							<SubmitButton type="submit" color={theme.colors.white}>
								<Text {...textProps}>
									{state.succeeded ? 'Thanks for joining!' : 'Submit'}
								</Text>
							</SubmitButton>
						</Flex>
					)}
					{hasCTA && (
						<Flex>
							<MenuButton href={cta?.url} color={theme.colors.white}>
								{cta?.label}
							</MenuButton>
						</Flex>
					)}
				</ContentWrapper>
			</NewsletterWrapper>
		</ThemeProvider>
	);
};

export default NewsletterSection;
