import { rem } from 'polished';
import styled from 'styled-components';
import { SwoopImage } from '../../../common/sections';

export const CheersImg1 = styled(SwoopImage)`
	position: absolute;
	top: ${rem(-60)};
	right: ${rem(-40)};
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		top: ${rem(-90)};
		right: ${rem(-150)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		top: ${rem(-120)};
		right: ${rem(-20)};
	}
`;

export const CheersImg2 = styled(SwoopImage)`
	position: absolute;
	bottom: ${rem(-70)};
	left: ${rem(-40)};
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		bottom: ${rem(-70)};
		left: ${rem(-150)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		top: ${rem(120)};
		left: ${rem(0)};
	}
`;
