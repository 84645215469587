import { rem } from 'polished';
import React, { useRef } from 'react';

import {
	ContentContainer,
	ImgContent,
	MainImage,
	SECTION_TITLE_MARGIN_BOTTOM,
	TextContent,
	ValueImageContainer,
	ValueSectionContainer,
	ValueSectionWrapper,
} from '../../../common/sections';

import Text from '../../../common/text';

import { FairwayPicture, NewSpiritIcon, NewWrapIcon } from './styled';

export const ElevateYourGame = ({
	anchorRef = null,
	containerRef = null,
	onEnter = () => {},
	topOffset = '0',
	children = null,
	title = '',
	body = '',
	src = '/elements/hello-john.png',
	...rest
}) => {
	const DEFAULT_REF = useRef(null);
	const DEFAULT_CONTAINER_REF = useRef(null);
	return (
		<ValueSectionWrapper>
			<ValueSectionContainer
				{...rest}
				ref={containerRef || DEFAULT_CONTAINER_REF}
				position="relative"
				flexDirection="column"
				mt={[]}
			>
				<ContentContainer
					initial="hidden"
					whileInView="visible"
					viewport={{ once: false }}
					transition={{ duration: 1.5 }}
					variants={{
						visible: { opacity: 1 },
						hidden: { opacity: 0 },
					}}
				>
					<TextContent flex={[1, 1]} mb={SECTION_TITLE_MARGIN_BOTTOM}>
						<h3 className="mb-5">{title}</h3>
						<p>{body}</p>
					</TextContent>
					<ImgContent flex={[1, 'auto']} justifyContent="center">
						<ValueImageContainer>
							<MainImage
								className="hidden"
								ref={anchorRef || DEFAULT_REF}
								src={src}
								alt=""
							/>
							<NewSpiritIcon
								src="/wp-content/themes/theme-kit/images/elements/spirits.png"
								alt="golfer holding whiskey drink"
							/>
							<NewWrapIcon
								src="/wp-content/themes/theme-kit/images/elements/wraps-icon.png"
								alt="golfer holding whiskey drink"
							/>
							<FairwayPicture
								src="/wp-content/themes/theme-kit/images/elements/fairway.png"
								alt="golfer holding whiskey drink"
							/>
						</ValueImageContainer>
					</ImgContent>
				</ContentContainer>
			</ValueSectionContainer>
			{children}
		</ValueSectionWrapper>
	);
};
