import { Section } from '../common/sections';

import Fuse from 'fuse.js';
import { rem } from 'polished';
import React, { useRef, useState } from 'react';
import { Box } from 'rebass/styled-components';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../theme';
import Text from '../common/text';
import ArrowButton from '../icons/arrow-down';
import {
	Search,
	SearchWrapper,
	StyledAccordion,
	StyledAccordionBtn,
	StyledAccordionHeading,
	StyledAccordionItem,
	StyledAccordionPanel,
	StyledAccordionRowContainer,
	StyledHourglass,
} from './styled';

const WYSIWYGTextWrapper = styled(Box)`
	& p {
		font-family: ${({ theme }) => theme.typography.fonts.tertiary};
	}
`;

const WYSIWYGText = ({ text }) => {
	const ref = React.useRef();
	React.useEffect(() => {
		ref.current.outerHTML = text;
	}, []);
	return (
		<WYSIWYGTextWrapper
			px={rem(theme.space[3])}
			pt={rem(theme.space[5])}
			pb={rem(theme.space[4])}
			fontFamily={theme.typography.fonts.tertiary}
		>
			<Text
				className="faq-styled-accordion-panel-text"
				color={theme.colors.calcite}
				ref={ref}
			/>
		</WYSIWYGTextWrapper>
	);
};
const AccordionPanel = ({ question, response, ...rest }) => {
	return (
		<StyledAccordionItem className="faq-styled-accordion-item" {...rest}>
			<StyledAccordionHeading className="faq-styled-accordion-heading">
				<StyledAccordionBtn className="faq-styled-accordion-btn">
					<Text
						className="faq-styled-accordion-text"
						variant="accordionTitle"
						width={'80%'}
					>
						{question}
					</Text>
					<ArrowButton className="faq-styled-arrow" fill={theme.colors.orange} />
				</StyledAccordionBtn>
			</StyledAccordionHeading>
			<StyledAccordionPanel className="faq-styled-accordion-panel">
				{/* <Text
					className="faq-styled-accordion-panel-text"
					color={theme.colors.calcite}
					px={rem(theme.space[3])}
					pt={rem(theme.space[5])}
					pb={rem(theme.space[4])}
					as={'p'}
					dangerouslySetInnerHTML={{ __html: response }}
				></Text> */}
				<WYSIWYGText text={response} />
			</StyledAccordionPanel>
		</StyledAccordionItem>
	);
};

const getSearchResults = (acc, cv, idx) => {
	if (idx === 0 || idx % 2 === 0) {
		acc[0].push(cv.item);
	} else {
		acc[1].push(cv.item);
	}
	return acc;
};

export default function FAQ({ faqs, columnLayout, displaySearch }) {
	const fuse = new Fuse(faqs.flat(), {
		keys: ['question', 'response'],
	});

	const [currentItems, setCurrentItems] = useState(
		columnLayout === 2 ? faqs : faqs.flat()
	);

	const handleSearch = ({ target: { value } }) => {
		if (value) {
			const result = fuse.search(value);
			const finalResult = result.reduce(getSearchResults, [[], []]);
			setCurrentItems(finalResult);
		} else {
			setCurrentItems(columnLayout === 2 ? faqs : faqs.flat());
		}
	};

	const container = useRef(null);

	return (
		<ThemeProvider theme={theme}>
			<Section
				className="faq-section"
				flexDirection="column"
				pt={['0 !important', `${rem(theme.space[6])} !important`]}
				mb={6}
			>
				{displaySearch && (
					<SearchWrapper className="faq-search-wrapper" position="relative" mb={6}>
						<StyledHourglass
							className="faq-styled-hourglass"
							position="absolute"
							left={4}
							top="50%"
							transform="translateY(-50%)"
						/>
						<Search
							className="faq-search"
							type="text"
							variant="h4"
							color="white"
							pl={6}
							py={3}
							placeholder="Search"
							maxWidth={['100%', rem(400)]}
							onChange={handleSearch}
						/>
					</SearchWrapper>
				)}
				<div ref={container}>
					<StyledAccordion
						columns={columnLayout}
						allowMultipleExpanded={true}
						allowZeroExpanded={true}
					>
						{currentItems.map((rows, ridx) => {
							if (columnLayout === 1) {
								return (
									<StyledAccordionRowContainer
										columns={columnLayout}
										key={`faq-items-row-${ridx}`}
									>
										<AccordionPanel
											className="faq-accordion-panel"
											key={`accordion-panel-${ridx}`}
											{...rows}
										/>
									</StyledAccordionRowContainer>
								);
							}
							return (
								<StyledAccordionRowContainer
									columns={columnLayout}
									key={`faq-items-row-${ridx}`}
								>
									{rows.map((item, idx) => (
										<AccordionPanel
											className="faq-accordion-panel"
											key={`accordion-panel-${ridx}-${idx}`}
											{...item}
										/>
									))}
								</StyledAccordionRowContainer>
							);
						})}
					</StyledAccordion>
				</div>
			</Section>
		</ThemeProvider>
	);
}
