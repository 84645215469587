import { rem } from 'polished';
import React, { useRef } from 'react';
import { Waypoint } from 'react-waypoint';

import {
	BoostRevenueAddToCart,
	BoostRevenueLemonade,
	BoostRevenueSandwich,
} from './styled';

import {
	ImgContent,
	MainImage,
	TextContent,
	ValueImageContainer,
	ValueSectionContainer,
	ValueSectionWrapper,
	ContentContainer,
} from '../../../common/sections';

import { SECTION_TITLE_MARGIN_BOTTOM } from '../../../common/sections';
import Text from '../../../common/text';

export const BoostRevenueSection = ({
	containerRef = null,
	anchorRef = null,
	onEnter = () => {},
	topOffset = '0',
	children = null,
	title = '',
	body = '',
	src = '/elements/hello-john.png',
	...rest
}) => {
	const DEFAULT_REF = useRef(null);
	return (
		<Waypoint onEnter={onEnter} bottomOffset={topOffset}>
			<ValueSectionWrapper ref={containerRef}>
				<ValueSectionContainer
					{...rest}
					position="relative"
					flexDirection="column"
					className="container"
					mt={[]}
				>
					<ContentContainer
						initial="hidden"
						whileInView="visible"
						viewport={{ once: false }}
						transition={{ duration: 1.5 }}
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0 },
						}}
					>
						<TextContent flex={[1, 1]} mb={SECTION_TITLE_MARGIN_BOTTOM}>
							<h3 className="mb-5">{title}</h3>
							<p>{body}</p>
						</TextContent>
						<ImgContent flex={[1, 'auto']} justifyContent="center">
							<ValueImageContainer>
								<MainImage
									className="hidden"
									ref={anchorRef || DEFAULT_REF}
									src={src}
									alt=""
								/>
								<BoostRevenueSandwich
									width={[130, 200, 140]}
									src="/wp-content/themes/theme-kit/images/swoop/courses/courses-sandwich-badge.svg"
									alt="golfer holding whiskey drink"
								/>
								<BoostRevenueAddToCart
									width={[300, 440, 320]}
									src="/wp-content/themes/theme-kit/images/swoop/courses/courses-add-to-cart.png"
									alt="golfer holding whiskey drink"
								/>
								<BoostRevenueLemonade
									width={[90, 130]}
									src="/wp-content/themes/theme-kit/images/swoop/courses/courses-lemonade.png"
									alt="golfer holding whiskey drink"
								/>
							</ValueImageContainer>
						</ImgContent>
					</ContentContainer>
				</ValueSectionContainer>
				{children}
			</ValueSectionWrapper>
		</Waypoint>
	);
};
