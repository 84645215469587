import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useAnimationControls } from 'framer-motion';
import { useViewport } from 'use-viewport';
import { useOnScreen } from '../../hooks/useOnScreen';
import theme from '../../theme';
import { ElevateYourGame } from './sections/elevate-your-game';
import { OrderMore } from './sections/order-more';
import { KeepPlaying } from './sections/keep-playing';
import { Cheers } from './sections/cheers';

import { ThemeProvider } from 'styled-components';

import {
	BackgroundImage,
	BoxPageSectionContainer,
	MotionDivWithSpacing,
	ValueSectionFloatingImage,
	ValueSectionFloatingImageContainer,
	Circle1,
	Circle2,
	Circle3,
} from './styled';

export default function ScrollableStoryBoard() {
	const controls = useAnimationControls();
	const { height } = useViewport();
	const floatingImageRef = useRef(null);
	const firstImageRef = useRef(null);
	const floatingImageAnchorStartRef = useRef(null);
	const floatingImageAnchorFinalRef = useRef(null);
	const floatingImageContainerFinalRef = useRef(null);
	const firstImageContainerRef = useRef(null);
	const [, setStickyImgPadding] = useState(0);
	const [topOffset, setTopOffset] = useState(0);
	const [, setBottomOffset] = useState(0);
	const [imageSrc, setImageSrc] = useState(
		'/wp-content/themes/theme-kit/images/swoop/courses/courses-sandwich-screen.png'
	);

	const [firstSection, firstSectionIsVisible] = useOnScreen({
		threshold: 0.6,
	});
	const [secondSection, secondSectionIsVisible] = useOnScreen({
		threshold: 0.6,
	});
	const [thirdSection, thirdSectionIsVisible] = useOnScreen({
		threshold: 0.6,
	});
	const [fourthSection, fourthSectionIsVisible] = useOnScreen({
		threshold: 0.6,
	});

	useEffect(() => {
		if (firstSectionIsVisible) {
			(async () => {
				await controls.start({ opacity: 0, transition: { duration: 0.5 } });
				await setImageSrc(
					'/wp-content/themes/theme-kit/images/swoop/courses/courses-sandwich-screen.png'
				);
				await controls.start({ opacity: 1, transition: { duration: 0.5 } });
			})();
		}
	}, [firstSectionIsVisible]);

	useEffect(() => {
		if (secondSectionIsVisible) {
			(async () => {
				await controls.start({ opacity: 0 });
				await setImageSrc(
					'/wp-content/themes/theme-kit/images/swoop/courses/courses-turkey-club.png'
				);
				await controls.start({ opacity: 1 });
			})();
		}
	}, [secondSectionIsVisible]);

	useEffect(() => {
		if (thirdSectionIsVisible) {
			(async () => {
				await controls.start({ opacity: 0 });
				setImageSrc(
					'/wp-content/themes/theme-kit/images/swoop/courses/courses-black-screen.png'
				);
				await controls.start({ opacity: 1 });
			})();
		}
	}, [thirdSectionIsVisible]);

	useEffect(() => {
		if (fourthSectionIsVisible) {
			(async () => {
				await controls.start({ opacity: 0 });
				await setImageSrc(
					'/wp-content/themes/theme-kit/images/swoop/golfers/golfers-phone-4.png'
				);
				await controls.start({ opacity: 1 });
			})();
		}
	}, [fourthSectionIsVisible]);

	useLayoutEffect(() => {
		const containerPos = firstImageContainerRef.current.getBoundingClientRect().y;
		const imagePos = firstImageRef.current.getBoundingClientRect().y;
		const deltaForPadding = Math.abs(containerPos - imagePos);
		setStickyImgPadding(deltaForPadding);
	}, [
		floatingImageAnchorStartRef.current,
		firstImageRef.current,
		firstImageContainerRef.current,
	]);

	useEffect(() => {
		const imagePos = floatingImageAnchorFinalRef.current.getBoundingClientRect().bottom;
		const containerPos =
			floatingImageContainerFinalRef.current.getBoundingClientRect().bottom;
		const deltaForBottom = Math.abs(imagePos - containerPos);
		setBottomOffset(deltaForBottom);
	}, [floatingImageAnchorFinalRef.current, floatingImageContainerFinalRef.current]);

	useEffect(() => {
		setTimeout(() => {
			const top = (height - floatingImageRef.current.clientHeight) / 2;
			setTopOffset(top);
		}, 500);
	}, [height, floatingImageRef.current, setTopOffset]);

	return (
		<ThemeProvider theme={theme}>
			<div className="ValueSections" style={{ position: 'relative' }}>
				<ValueSectionFloatingImageContainer mb={[, 8]}>
					<ValueSectionFloatingImage ref={floatingImageRef} style={{ top: topOffset }}>
						<BackgroundImage src={imageSrc} animate={controls} />
						<img
							src="/wp-content/themes/theme-kit/images/elements/blank-iphone.png"
							alt=""
							style={{ width: '100%', zIndex: 100000 }}
						/>
					</ValueSectionFloatingImage>
				</ValueSectionFloatingImageContainer>
				<BoxPageSectionContainer hasOverflowY={false}>
					<MotionDivWithSpacing ref={firstSection} mb={[6, 7, 8]}>
						<ElevateYourGame
							mt={[8]}
							containerRef={firstImageContainerRef}
							anchorRef={firstImageRef}
							title="ELEVATE YOUR GAME"
							body="Perfect your pitch. Eliminate mid-round hanger with food + drink delivered to your exact location."
							src="/wp-content/themes/theme-kit/images/swoop/golfers/golfers-phone-1.png"
						>
							<Circle1 />
						</ElevateYourGame>
					</MotionDivWithSpacing>
					<MotionDivWithSpacing ref={secondSection} mb={[6, 7, 8]}>
						<OrderMore
							pt={[8]}
							title="ORDER MORE"
							body="Enjoy all of the culinary comforts for the club grill, on the greens."
							src="/wp-content/themes/theme-kit/images/swoop/golfers/golfers-phone-2.png"
						>
							<Circle2 />
						</OrderMore>
					</MotionDivWithSpacing>
					<MotionDivWithSpacing ref={thirdSection} mb={[6, 7, 8]}>
						<KeepPlaying
							title="KEEP PLAYING"
							body="On-demand, on the course. Fuel your game with refreshments, and don’t stop playing."
							pt={[8]}
							src="/wp-content/themes/theme-kit/images/swoop/golfers/golfers-phone-3.png"
						>
							<Circle1 />
							<Circle3 />
						</KeepPlaying>
					</MotionDivWithSpacing>
					<MotionDivWithSpacing ref={fourthSection} mb={[7, 8, 7]}>
						<Cheers
							pt={[8]}
							containerRef={floatingImageContainerFinalRef}
							anchorRef={floatingImageAnchorFinalRef}
							title="CHEERS"
							body="Make more memorable rounds by having a round of drinks delivered during your game."
							src="/wp-content/themes/theme-kit/images/swoop/golfers/golfers-phone-4.png"
						/>
					</MotionDivWithSpacing>
				</BoxPageSectionContainer>
			</div>
		</ThemeProvider>
	);
}
