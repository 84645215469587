import React from 'react';
import { Flex } from 'rebass/styled-components';
import { ThemeProvider } from 'styled-components';

import theme from '../../theme';
import Instagram from '../icons/instagram';
import LinkedIn from '../icons/linkedin';
import Twitter from '../icons/twitter';
import cardData from '../../data/ambassadors';

import {
	ControlContainer,
	LeftControl,
	RightControl,
	useCustomCarousel,
} from '../testimonials';

import {
	AmassadorsCarouselWrapper,
	Card,
	CardImageWrapper,
	ControlWrapper,
	EmblaChild,
	EmblaParent,
	SocialsContainer,
	Tag,
} from './styled';
import Link from '../common/link';

export const AmbassadorCarousel = ({ cards = cardData, ...rest }) => {
	const { scrollPrev, scrollNext, emblaRef } = useCustomCarousel();
	return (
		<ThemeProvider theme={theme}>
			<AmassadorsCarouselWrapper>
				<EmblaParent ref={emblaRef}>
					<EmblaChild>
						{cards.map(({ title, tag, location, comment, imgSrc, socials }, idx) => {
							return (
								<Card key={`ambassador-card-${idx}`}>
									<CardImageWrapper className="img-wrapper">
										<img src={imgSrc} alt="" />
									</CardImageWrapper>
									<Flex className="card__content" px={[4, 5, 6]} flexDirection="column">
										<h5 className="card__title mb-4">{title}</h5>
										<h6 className="card__location mb-0">{location}</h6>
										<Tag className="card__tag mb-4">{tag}</Tag>
										<small className="card__body mb-3">{comment}</small>
										<SocialsContainer>
											{((socials) => {
												return (
													socials &&
													socials.map(({ platform, link }) => {
														switch (platform) {
															case 'Instagram':
																return (
																	<a key={`${platform}-${link}`} href={link}>
																		<Instagram fill={theme.colors.orange} />
																	</a>
																);
															case 'X':
																return (
																	<a key={`${platform}-${link}`} href={link}>
																		<Twitter fill={theme.colors.orange} />
																	</a>
																);
															case 'LinkedIn':
																return (
																	<a key={`${platform}-${link}`} href={link}>
																		<LinkedIn fill={theme.colors.orange} />
																	</a>
																);
															default:
																return null;
														}
													})
												);
											})(socials)}
										</SocialsContainer>
									</Flex>
								</Card>
							);
						})}
					</EmblaChild>
				</EmblaParent>
				<ControlWrapper flexDirection="column" pl={[4, , 0]} mt={[5, 6]}>
					<ControlContainer mb={[4]}>
						<LeftControl onClick={scrollPrev} />
						<RightControl onClick={scrollNext} />
					</ControlContainer>
					<Link href="/contact">Become a Swoop Ambassador</Link>
				</ControlWrapper>
			</AmassadorsCarouselWrapper>
		</ThemeProvider>
	);
};

export default AmbassadorCarousel;
