import { rem } from 'polished';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Golfball = styled(motion.div)`
	position: absolute;
	bottom: 0;
	left: ${rem(-20)};
	display: none;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		top: ${rem(-100)};
		left: ${rem(-100)};
		z-index: 100000;
		display: block;
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		top: ${rem(-210)};
		left: unset;
		right: ${rem(-260)};
		z-index: 100000;
		display: block;
	}
`;
