import { rem } from 'polished';
import React from 'react';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';

const Card = styled(Flex)`
	flex: 1;
	position: relative;
	flex: 0 0 95%;
	@media screen and (min-width: 768px) {
		flex: 0 0 65%;
		flex-direction: row;
	}
`;

const ImageWrapper = styled('div')`
	width: 100%;
	height: ${rem(400)};
	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
`;
const CardContent = styled(Flex)`
	@media screen and (min-width: 768px) {
		flex-direction: row;
	}
`;

const CarouselCard = ({ imgSrc, ...rest }) => {
	return (
		<Card {...rest}>
			<CardContent className="testimonials-carousel__card-content" width={'100%'}>
				<ImageWrapper className="testimonials-carousel__image-wrapper">
					<img src={imgSrc} />
				</ImageWrapper>
			</CardContent>
		</Card>
	);
};
export default CarouselCard;
