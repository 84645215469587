import { AnimatePresence, useAnimation } from 'framer-motion';
import { rem } from 'polished';
import React, { useState } from 'react';
import theme from '../../theme';
import Text from '../common/text';

import {
	DropdownContainer,
	DropdownHeader,
	DropdownListContainer,
	DropdownListItem,
} from './styled';

const listContainer = {
	visible: {
		height: 300,
	},
	hidden: {
		height: 'auto',
	},
};

const list = {
	visible: {
		height: 'auto',
		opacity: 1,
		paddingBottom: rem(theme.space[4]),
		transition: {
			duration: 0.4,
		},
	},
	hidden: {
		height: 0,
		opacity: 0,
		paddingBottom: 0,
		transition: {
			duration: 0.2,
		},
	},
};

const item = {
	visible: (custom) => ({
		opacity: 1,
		x: 0,
		transition: {
			duration: custom,
			stiffness: 100,
			type: 'tween',
			delay: custom,
		},
	}),
	hidden: (custom) => ({
		transition: { duration: custom, type: 'tween', delay: custom },
		opacity: 0,
		x: 20,
	}),
};

const inquiryItems = [
	{
		value: 'support',
		label: 'Support',
	},
	{
		value: 'ambassadors',
		label: 'Ambassadors',
	},
	{
		value: 'affiliates',
		label: 'Affiliates',
	},
	{
		value: 'press',
		label: 'Press',
	},
	{
		value: 'careers',
		label: 'Careers',
	},
	{
		value: 'general-inquiries',
		label: 'General Inquiries',
	},
];

let initialState = {
	value: 'inquiry',
	label: 'Inquiry',
};

export const DropdownMenu = ({
	selectedOption = initialState,
	setSelectedOption = (option) => {},
	...rest
}) => {
	const controls = useAnimation();
	const itemControls = useAnimation();
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = async () => {
		if (isOpen) {
			await itemControls.start('hidden');
			await controls.start('hidden');
		} else {
			await controls.start('visible');
			await itemControls.start('visible');
		}
		setIsOpen(!isOpen);
	};

	return (
		<DropdownContainer initial="hidden" animate={controls} variants={listContainer}>
			<DropdownHeader {...rest} onClick={toggleDropdown}>
				<Text
					variant="h4"
					color={
						selectedOption.value !== 'inquiry' ? theme.colors.orange : theme.colors.black
					}
					fontWeight="bold"
				>
					{selectedOption.label}
				</Text>
				<input
					type="text"
					name="inquiry"
					onChange={() => {}}
					value={selectedOption.value}
					hidden
				/>
				{/* <DownArrow /> */}
			</DropdownHeader>
			<DropdownListContainer
				px={4}
				pb={0}
				initial="hidden"
				animate={controls}
				variants={list}
			>
				<AnimatePresence>
					{inquiryItems.map((inquiry, idx) => {
						return (
							<DropdownListItem
								key={`${inquiry.value}-${idx}`}
								exit="hidden"
								initial="hidden"
								variants={item}
								layoutId={`${inquiry.value}-${idx}`}
								custom={(idx + 1) * 0.1}
								animate={itemControls}
								active={inquiry.value === selectedOption.value ? true : undefined}
								onClick={() => {
									setSelectedOption(inquiry);
									toggleDropdown();
								}}
							>
								{inquiry.label}
							</DropdownListItem>
						);
					})}
				</AnimatePresence>
			</DropdownListContainer>
		</DropdownContainer>
	);
};
