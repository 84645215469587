import imagesloaded from 'imagesloaded';
import AOS from 'aos';
import Splitting from 'splitting';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Carousel from './components/carousel';
import AmbassadorsCarousel from './components/ambassadors';
import Testimonials from './components/testimonials';
import ScrollableStoryBoardCourses from './components/scrollable-storyboard-courses';
import ScrollableStoryBoardGolfers from './components/scrollable-storyboard-golfers';
import ContactForm from './components/contact-form';
import InstagramFeed from './components/instagram-feed';
import NewsletterSection from './components/newsletter';
import FAQ from './components/faq-section';
import CarouselWithText from './components/carousel-with-text';

///////////////////////////////
// Initialize
///////////////////////////////

// Imagesloaded
window.imagesLoaded = imagesloaded;

// AOS
AOS.init({
	duration: 800,
});

// Splitting
Splitting();

///////////////////////////////
// Functions
///////////////////////////////

//Headroom
var navbarWrapper = document.querySelector('.headroom');
var navbar = document.querySelector('.navbar');

var headroom = new Headroom(navbarWrapper, {
	tolerance: {
		up: 2,
		down: 2,
	},
});
headroom.init();

//Nav Icon
$('#nav-icon').click(function () {
	$(this).toggleClass('open');
});

// Page Section Background Image Preloader
imagesloaded('.page-section__background', { background: true }, function () {
	const backgrounds = document.querySelectorAll('.page-section__background');
	backgrounds.forEach((item) => {
		item.classList.add('page-section__background--loaded');
	});
});

// Fade out page header background
const header = document.querySelector('.page-header .page-section__background');
if (header) {
	window.addEventListener('scroll', function (e) {
		const offset = $(window).scrollTop();
		if (offset <= header.clientHeight) {
			header.classList.add('scrolling');
			header.style.opacity = 1 - offset / header.clientHeight;
		} else {
			header.classList.remove('scrolling');
		}
	});
}

// Page section background color change
// This script changes the body background color based on data-section. It's currently turned off on page-builder.scss.
$(window)
	.scroll(function () {
		// selectors
		var $window = $(window),
			$body = $('body'),
			$panel = $('section, header');

		// Change 33% earlier than scroll position so colour is there when you arrive.
		var scroll = $window.scrollTop() + $window.height() / 2;

		$panel.each(function () {
			var $this = $(this);

			// if position is within range of this panel.
			// So position of (position of top of div <= scroll position) && (position of bottom of div > scroll position).
			// Remember we set the scroll to 33% earlier in scroll var.
			if (
				$this.position().top <= scroll &&
				$this.position().top + $this.height() > scroll
			) {
				// Remove all classes on body with color-
				$body.removeClass(function (index, css) {
					return (css.match(/(^|\s)fade-\S+/g) || []).join(' ');
				});

				// Add class of currently active div
				$body.addClass('fade-' + $(this).data('section'));
			}
		});
	})
	.scroll();

//Object Fit IE Fix
if (window && window.Modernizr && !Modernizr.objectfit) {
	$('.image-container').each(function () {
		var $container = $(this),
			imgUrl = $container.find('img').prop('src');
		if (imgUrl) {
			$container
				.css('backgroundImage', 'url(' + imgUrl + ')')
				.addClass('compat-object-fit');
		}
	});
}

// Grid View and List View on Loops
$('.view-switcher a').on('click', function () {
	if ($(this).hasClass('grid-icon')) {
		$('#view-switcher-items').removeClass('list').addClass('grid');
	} else if ($(this).hasClass('list-icon')) {
		$('#view-switcher-items').removeClass('grid').addClass('list');
	}
});

// Grid View and List View Local Storage
$(function () {
	var list = localStorage.getItem('Listview') || ''; // get choice or nothing
	if (list === 'yes') $('#view-switcher-items').removeClass('grid').addClass('list'); // if trigger IS needed

	$('.view-switcher a').on('click', function () {
		if ($('#view-switcher-items').hasClass('list')) {
			localStorage.setItem('Listview', 'yes'); // "clear" choice
		} else if ($('#view-switcher-items').hasClass('grid')) {
			localStorage.setItem('Listview', '');
		}
	});
});
////////////////////////////////////
// React Components
////////////////////////////////////

function registerComponent(id, Component) {
	if (!id) {
		return;
	}
	const rootInstances = document.querySelectorAll(`[id^="${id}"]`);
	if (rootInstances && rootInstances.length > 0) {
		rootInstances.forEach((root) => {
			const props = JSON.parse(root.dataset.props) || {};
			createRoot(root).render(<Component key={`${root.id}`} {...props} />);
		});
	}
}


// Enable Popovers
var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
})


/**
 * Testimonial Carousel
 */
registerComponent('react-testimonials', Testimonials);
/**
 * Standard Image Carousel
 */
registerComponent('react-carousel', Carousel);
/**
 * Standard Image Carousel
 */
registerComponent('react-carousel-with-text', CarouselWithText);
/**
 * Ambassadors Carousel
 */
registerComponent('react-ambassador-carousel', AmbassadorsCarousel);
/**
 * Scrollable Story Board Courses
 */
registerComponent('react-scrollable-storyboard-courses', ScrollableStoryBoardCourses);
/**
 * Scrollable Story Board Golfers
 */
registerComponent('react-scrollable-storyboard-golfers', ScrollableStoryBoardGolfers);
/**
 * Contact Form
 */
registerComponent('react-contact-form', ContactForm);
/**
 * Instagram Feed
 */
registerComponent('react-instagram-feed', InstagramFeed);
/**
 * Newsletter Section
 */
registerComponent('react-newsletter', NewsletterSection);
/**
 * FAQ Section
 */
registerComponent('react-faq-section', FAQ);
