import React, { useState, useCallback, useEffect } from 'react';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { rem } from 'polished';
import useEmblaCarousel from 'embla-carousel-react';
import ArrowControl from '../icons/arrow-control';

const PhotoCarousel = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	-webkit-overflow-scrolling: touch;
`;

export const EmblaParent = styled.div``;
export const EmblaContainer = styled(PhotoCarousel)``;

const Card = styled(Flex)`
	flex: 1;
	position: relative;
	flex: 0 0 95%;
	@media screen and (min-width: 768px) {
		flex: 0 0 75%;
		flex-direction: row;
	}
`;

const ImageWrapper = styled('div')`
	width: 150px;
	height: ${rem(400)};
	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
`;

const ContentWrapper = styled(Flex)`
	flex: 1;
	@media screen and (min-width: 768px) {
		padding: 10%;
	}
`;

export const LeftControl = styled(ArrowControl)`
	transform: rotate(180deg);
	margin-right: ${rem(20)};
	cursor: pointer;
`;

export const RightControl = styled(ArrowControl)`
	cursor: pointer;
`;

const LittleCircle = styled('div')`
	position: absolute;
	height: 36px;
	width: 36px;
	border-radius: 50%;
	display: block;
	background-color: transparent;
	border-color: #ff8b00;
	border-width: 0.5px;
	border-style: solid;
	left: 33px;
`;

const SolidCircle = styled('div')`
	position: relative;
	height: 12px;
	width: 12px;
	border-radius: 50%;
	display: block;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #ff8b00;
`;

export const LittleCircleWithDot = ({ className, ...rest }) => {
	return (
		<LittleCircle className={`${className} little-circle`} {...rest}>
			<SolidCircle className="solid-circle" />
		</LittleCircle>
	);
};

const DesktopCWD = styled(LittleCircleWithDot)`
	top: -55px;
	left: 0;
	display: none;
	@media screen and (min-width: 768px) {
		display: block;
	}
`;

const MobileCWD = styled(LittleCircleWithDot)`
	display: block;
	position: absolute;
	top: -5px;
	left: 166px;
	bottom: 0;

	@media screen and (min-width: 768px) {
		display: none;
	}
`;

export const ControlContainer = styled(Flex)`
	position: relative;
	@media screen and (min-width: 768px) {
		padding-left: 0;
		z-index: 10;
	}
`;

const TestimonialControlContainer = styled(ControlContainer)`
	@media screen and (min-width: 1440px) {
		transform: translate(-140px, 90px);
	}
`;

const CustomTitle = styled('div')`
	position: relative;
`;

export const Block = styled('div')`
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-right: auto !important;
	margin-left: auto !important;
	@media (min-width: 1024px) {
		max-width: 980px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;

const CardContent = styled(Flex)`
	@media screen and (min-width: 768px) {
		flex-direction: row;
	}
`;

const TestimonialCard = ({
	title,
	location,
	body,
	name,
	occupation,
	imgSrc,
	image,
	isDisplayingSingleCard,
	...rest
}) => {
	return (
		<Card {...rest}>
			<CardContent className="testimonials-carousel__card-content" width={'100%'}>
				<ImageWrapper className="testimonials-carousel__image-wrapper">
					<img src={imgSrc || image || ''} />
				</ImageWrapper>
				<ContentWrapper pl={3} pr={4} flex="1" flexDirection={['column']}>
					<CustomTitle className="mb-4">
						<DesktopCWD />
						{title} | <CustomTitle fontWeight="bold">{location}</CustomTitle>
					</CustomTitle>
					<small
						className="mb-5"
						variant="bodySecondary"
						dangerouslySetInnerHTML={{ __html: body }}
					/>
					<p mb={[4, 6]}>
						{name} {'|'} {occupation}
					</p>
				</ContentWrapper>
			</CardContent>
		</Card>
	);
};

export const useCustomCarousel = () => {
	const [currentIndex, setSelectedIndex] = useState(0);

	const [emblaRef, emblaApi] = useEmblaCarousel({
		align: 'start',
		skipSnaps: false,
		watchDrag: false,
	});

	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

	const scrollPrev = useCallback(() => {
		emblaApi && emblaApi.scrollPrev();
	}, [emblaApi]);

	const scrollNext = useCallback(() => {
		emblaApi && emblaApi.scrollNext();
	}, [emblaApi]);

	const onSelect = useCallback(() => {
		if (!emblaApi) return;
		setPrevBtnEnabled(emblaApi.canScrollPrev());
		setNextBtnEnabled(emblaApi.canScrollNext());
		setSelectedIndex(emblaApi.selectedScrollSnap());
	}, [emblaApi]);

	useEffect(() => {
		if (!emblaApi) return;
		emblaApi.on('select', onSelect);
		onSelect();
	}, [emblaApi, onSelect]);

	return {
		currentIndex,
		setSelectedIndex,
		prevBtnEnabled,
		setPrevBtnEnabled,
		nextBtnEnabled,
		setNextBtnEnabled,
		scrollPrev,
		scrollNext,
		onSelect,
		emblaRef,
		emblaApi,
	};
};

const TestimonialsCarouselWrapper = styled(Block)`
	padding-right: 0;
	&.testimonials-carousel--single {
		padding-left: 0;

		.testimonials-carousel__embla-container {
			justify-content: center;
		}
		.testimonials-carousel__card {
			flex-direction: column;
			@media screen and (min-width: 768px) {
				flex-direction: row;
			}
		}
		.testimonials-carousel__card-content {
			flex-direction: column;
			@media screen and (min-width: 768px) {
				flex-direction: row;
			}
		}
		.testimonials-carousel__image-wrapper {
			width: unset;
			max-height: ${rem(300)};
			margin-bottom: ${rem(20)};
			padding: ${rem(16)};
			@media screen and (min-width: 768px) {
				width: 150px;
				max-height: ${rem(400)};
			}
		}
	}
`;

export const TestimonialsCarousel = ({ cards = [] }) => {
	const { scrollPrev, scrollNext, emblaRef } = useCustomCarousel();
	const isDisplayingMultipleCards = cards && cards?.length > 1;
	return (
		<>
			<TestimonialsCarouselWrapper
				className={`testimonials-carousel${
					!isDisplayingMultipleCards ? ' testimonials-carousel--single' : ''
				}`}
			>
				{isDisplayingMultipleCards && (
					<TestimonialControlContainer mb={[5]}>
						<LeftControl
							className="testimonials-carousel__control"
							onClick={scrollPrev}
						/>
						<RightControl
							className="testimonials-carousel__control"
							onClick={scrollNext}
						/>
						<MobileCWD className="testimonials-carousel__mobile-cwd" />
					</TestimonialControlContainer>
				)}
				<EmblaParent ref={emblaRef}>
					<EmblaContainer className="testimonials-carousel__embla-container">
						{cards.map((item, idx) => {
							return (
								<TestimonialCard
									className="testimonials-carousel__card"
									key={`idx-${idx}`}
									{...item}
								/>
							);
						})}
					</EmblaContainer>
				</EmblaParent>
			</TestimonialsCarouselWrapper>
		</>
	);
};

export default TestimonialsCarousel;
