import { motion } from 'framer-motion';
import { rem } from 'polished';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { color, compose, layout, space, variant } from 'styled-system';
import { variants } from '../common/text';

export const ContactFormContainer = styled(Flex)`
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-right: auto !important;
	margin-left: auto !important;
	overflow: visible;
	position: relative;

	@media (min-width: 1024px) {
		max-width: 980px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;

export const DualSection = styled(Box)`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(2, auto);
	row-gap: ${({ theme }) => rem(theme.space[5])};

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		column-gap: ${({ theme }) => rem(theme.space[6])};
	}
`;

export const ContactFormWrapper = styled('form')`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(5, auto);
	row-gap: ${({ theme }) => rem(theme.space[4])};
	position: relative;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(3, auto);
		grid-gap: ${({ theme }) => rem(theme.space[5])};

		.first {
			grid-column: 1 /4;
		}
		.second {
			grid-column: 4 / 7;
		}
		.third {
			grid-column: 1 / 4;
		}
		.fourth {
			grid-column: 4 /7;
		}
		.fifth {
			grid-column: 1 /7;
		}
		.sixth {
			grid-column: 1 /4;
		}
	}
`;

export const ExtendendInput = styled('input')(space);
export const ExtendendTextArea = styled('textarea')(space);

export const FormInput = styled(ExtendendInput)`
	background: transparent;
	border: 2px solid ${({ theme }) => theme.colors.black};
	border-radius: ${({ theme }) => rem(theme.space.xl)};
	box-sizing: border-box;
	width: 100%;
	font-size: ${rem(15)};
	line-height: ${rem(20)};
	letter-spacing: ${rem(0.38)};
	color: ${({ theme }) => theme.colors.orange} !important;
	text-transform: uppercase;
	font-family: ${({ theme }) => theme.typography.fonts.tertiary};
	font-weight: bold !important;

	::placeholder {
		color: black;
		font-weight: bold;
	}

	:focus,
	:active {
		outline: none;
		border: 2px solid ${({ theme }) => theme.colors.orange};
	}
	${variant({ variants })}
	${compose(color, layout)}
`;
export const FormTextArea = styled(ExtendendTextArea)`
	background: transparent;
	border: 2px solid ${({ theme }) => theme.colors.black};
	border-radius: ${({ theme }) => rem(theme.space.xl)};
	box-sizing: border-box;
	width: 100%;
	font-size: ${rem(15)};
	line-height: ${rem(20)};
	letter-spacing: ${rem(0.38)};
	color: ${({ theme }) => theme.colors.orange} !important;
	text-transform: uppercase;
	font-family: ${({ theme }) => theme.typography.fonts.tertiary};
	font-weight: bold !important;

	::placeholder {
		color: black;
		font-weight: bold;
	}

	:focus,
	:active {
		outline: none;
		border: 2px solid ${({ theme }) => theme.colors.orange};
	}
	${variant({ variants })}
	${compose(color, layout)}
`;

export const FormInputWrapper = styled(Box)`
	width: 100%;
	position: relative;
`;

export const ExtendendMotionDiv = styled(motion.div)(space);

export const DropdownContainer = styled(ExtendendMotionDiv)`
	cursor: pointer;
	background: white;
	border: 2px solid ${({ theme }) => theme.colors.black};
	border-radius: ${({ theme }) => rem(theme.space.xl)};
	box-sizing: border-box;
	width: 100%;
	position: relative;
	z-index: 100;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		position: absolute;
	}
`;
export const DropdownHeader = styled(ExtendendMotionDiv)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: ${rem(15)};
	line-height: ${rem(20)};
	letter-spacing: ${rem(0.38)};
	color: ${({ theme }) => theme.colors.black};
	text-transform: uppercase;
	font-family: ${({ theme }) => theme.typography.fonts.tertiary};
	font-weight: bold;
`;

export const DropdownListContainer = styled(ExtendendMotionDiv)`
	position: relative;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		position: absolute;
	}
`;

export const DropdownListItem = styled(ExtendendMotionDiv)`
	cursor: pointer;
	${({ active, theme }) =>
		active &&
		`
		font-weight: bold !important;
		color: ${theme.colors.orange} !important;
	`}
	&:last-of-type {
		padding-bottom: ${({ theme }) => theme.space[4]};
	}
`;
