import Text from '../common/text';
import { Flex } from 'rebass/styled-components';
import { ThemeProvider } from 'styled-components';
import theme from '../../theme';
import { Section } from '../common/sections';
import { InstaGrid, GridItem, LeftCircle, RightCircle } from './styled';
import Instagram from '../icons/instagram';
import React from 'react';

export const InstagramFeed = ({ title = '', body = '', images = [], ...rest }) => {
	let addColor = {};
	return (
		<ThemeProvider theme={theme}>
			<Section
				className="react-instagram-feed"
				flexDirection="column"
				mb={[6, 7]}
				{...rest}
			>
				<Flex mb={[6]} flexDirection={['column', 'row']}>
					<Text variant={'h4Uppercase'} mr="7" {...addColor}>
						{title}
					</Text>
					<Text variant={'h4Uppercase'} {...addColor}>
						{body}
					</Text>
				</Flex>
				<Flex flexDirection={['column', 'row']}>
					<Flex mb={[5]} mr={6}>
						<a href="https://www.instagram.com/swoopgolf/">
							<Instagram fill={theme.colors.orange} />
						</a>
					</Flex>
					<InstaGrid>
						{images &&
							images.map(({ image }, index) => (
								<GridItem key={index}>
									<a href="https://www.instagram.com/swoopgolf/">
										<img src={image} alt={'Instagram Image'} />
									</a>
								</GridItem>
							))}
					</InstaGrid>
				</Flex>
				<LeftCircle />
				<RightCircle />
			</Section>
		</ThemeProvider>
	);
};

export default InstagramFeed;
