import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useCustomCarousel } from '../../hooks/useCustomCarousel';
import theme from '../../theme';
import { ControlContainer, LeftControl, RightControl } from '../testimonials';
import {
	CarouselWrapper,
	ContentWrapper,
	Embla,
	EmblaContainer,
	EmblaSlide,
	EmblaSlideImg,
	EmblaSlideInner,
	EmblaViewport,
	Section,
} from './styled';

import defaultCards from '../../data/carousel';

const DEFAULT_PROPS = {
	cards: defaultCards,
};

export const Carousel = ({ images = DEFAULT_PROPS.cards }) => {
	const { scrollPrev, scrollNext, emblaRef } = useCustomCarousel();
	return (
		<ThemeProvider theme={theme}>
			<CarouselWrapper mb={6}>
				<Section
					flexDirection={['column', 'row']}
					justifyContent={[, 'space-between']}
					alignItems={[, 'flex-end']}
				>
					<ContentWrapper />
					<ControlContainer mt={[5]}>
						{images && images.length > 3 && (
							<>
								<LeftControl onClick={scrollPrev} />
								<RightControl onClick={scrollNext} />
							</>
						)}
					</ControlContainer>
				</Section>
				<Embla>
					<EmblaViewport ref={emblaRef}>
						<EmblaContainer>
							{images.map(({ imgSrc }, idx) => (
								<EmblaSlide key={`img-carousel-${idx}`}>
									<EmblaSlideInner>
										<EmblaSlideImg src={imgSrc} alt="current image" />
									</EmblaSlideInner>
								</EmblaSlide>
							))}
						</EmblaContainer>
					</EmblaViewport>
				</Embla>
			</CarouselWrapper>
		</ThemeProvider>
	);
};

export default Carousel;
