import { rem } from 'polished';
import React, { useRef } from 'react';
import { Waypoint } from 'react-waypoint';

import {
	ContentContainer,
	ElevateBigImg,
	ElevateSmallImg,
	ImgContent,
	MainImage,
	SECTION_TITLE_MARGIN_BOTTOM,
	SwoopImage,
	TextContent,
	ValueImageContainer,
	ValueSectionContainer,
	ValueSectionWrapper,
} from '../../../common/sections';

import Text from '../../../common/text';

import { Golfball } from './styled';

export const KeepPlaying = ({
	anchorRef = null,
	onEnter = () => {},
	topOffset = '0',
	children = null,
	title = '',
	body = '',
	src = '/elements/hello-john.png',
	...rest
}) => {
	const DEFAULT_REF = useRef(null);
	return (
		<Waypoint onEnter={onEnter} bottomOffset={topOffset}>
			<ValueSectionWrapper>
				<ValueSectionContainer
					{...rest}
					position="relative"
					flexDirection="column"
					mt={[]}
				>
					<ContentContainer
						initial="hidden"
						whileInView="visible"
						viewport={{ once: false }}
						transition={{ duration: 1.5 }}
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0 },
						}}
					>
						<TextContent flex={[1, 1]} mb={SECTION_TITLE_MARGIN_BOTTOM}>
							<h3 className="mb-5">{title}</h3>
							<p>{body}</p>
						</TextContent>
						<ImgContent flex={[1, 'auto']} justifyContent="center">
							<ValueImageContainer>
								<MainImage
									className="hidden"
									ref={anchorRef || DEFAULT_REF}
									src={src}
									alt=""
								/>
								<ElevateSmallImg>
									<SwoopImage
										width={[130, 200]}
										src="/wp-content/themes/theme-kit/images/swoop/golfers/elevate-small.png"
									/>
								</ElevateSmallImg>
								<ElevateBigImg>
									<SwoopImage
										width={[105, 160]}
										src="/wp-content/themes/theme-kit/images/swoop/golfers/elevate-big.png"
									/>
								</ElevateBigImg>
								<Golfball>
									<SwoopImage
										width={[105, 170]}
										src="/wp-content/themes/theme-kit/images/swoop/golfers/golfball.png"
									/>
								</Golfball>
							</ValueImageContainer>
						</ImgContent>
					</ContentContainer>
				</ValueSectionContainer>
				{children}
			</ValueSectionWrapper>
		</Waypoint>
	);
};
