import { rem } from 'polished';
import styled from 'styled-components';
import ArrowControl from '../icons/arrow-control';

export const EmblaImageParent = styled.div`
	margin-bottom: ${rem(28)};
`;

export const EmblaImageContainer = styled('div')`
	display: flex;
	flex-wrap: nowrap;
	-webkit-overflow-scrolling: touch;
	column-gap: ${rem(20)};
	@media screen and (min-width: 768px) {
		column-gap: ${rem(50)};
	}
`;

export const EmblaContentParent = styled('div')`
	overflow-x: hidden;
	margin-bottom: ${rem(80)};
`;

export const EmblaContentContainer = styled('div')`
	display: flex;
	margin-left: calc(${rem(16)} * -1);
`;

export const CarouselControlContainer = styled('div')`
	position: relative;
	@media screen and (min-width: 768px) {
		padding-left: 0;
		z-index: 10;
	}
`;

export const LeftControl = styled(ArrowControl)`
	transform: rotate(180deg);
	margin-right: ${rem(20)};
	cursor: pointer;
`;

export const RightControl = styled(ArrowControl)`
	cursor: pointer;
`;

export const CarouselWrapper = styled('div')`
	width: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
	&.testimonials-carousel--single {
		padding-left: 0;

		.testimonials-carousel__embla-container {
			justify-content: center;
		}
		.testimonials-carousel__card {
			flex-direction: column;
			@media screen and (min-width: 768px) {
				flex-direction: row;
			}
		}
		.testimonials-carousel__card-content {
			flex-direction: column;
			@media screen and (min-width: 768px) {
				flex-direction: row;
			}
		}
		.testimonials-carousel__image-wrapper {
			width: unset;
			max-height: ${rem(300)};
			margin-bottom: ${rem(20)};
			padding: ${rem(16)};
			@media screen and (min-width: 768px) {
				width: 150px;
				max-height: ${rem(400)};
			}
		}
	}
`;
