import { rem } from 'polished';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { space } from 'styled-system';

export const NewsletterWrapper = styled('form')`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: ${rem(260)} 1fr;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		grid-template-columns: 40% 60%;
		grid-template-rows: 1fr;
	}

	${space}
`;

export const ImageWrapper = styled('div')`
	background-image: url(${({ src }) => src});
	background-position: center;
	background-size: cover;
	height: 100%;
	img {
		display: none;
		height: 100%;
	}
`;

export const ContentWrapper = styled(Flex)`
	background: ${({ theme }) => theme.colors.orange};
`;

export const CustomInput = styled('input')`
	border: 2px solid ${({ theme, color }) => color || theme.colors.orange};
	border-radius: ${({ theme }) => rem(theme.space.xl)};
	padding: ${({ theme }) => `${rem(theme.space.md)} ${rem(theme.space.lg)}`};
	background: transparent;
	color: ${({ theme, color }) => color || theme.colors.white};
	margin-bottom: ${({ theme }) => rem(theme.space[4])};
	flex: 1;
	font-size: ${rem(15)};
	line-height: ${rem(20)};
	letters-pacing: ${rem(0.38)};
	text-transform: 'uppercase';
	font-family: ${({ theme }) => theme.typography.fonts.tertiary};
	font-weight: 700;

	::placeholder {
		text-transform: uppercase;
		color: ${({ theme, color }) => color || theme.colors.white};
		opacity: 0.8;
		font-size: ${rem(15)};
		line-height: ${rem(20)};
		letter-spacing: ${rem(0.38)};
		text-transform: 'uppercase';
		font-family: ${({ theme }) => theme.typography.fonts.tertiary};
		font-weight: 700;
		opacity: 0.5;
	}
	:-ms-input-placeholder {
		text-transform: uppercase;
		color: ${({ theme, color }) => color || theme.colors.white};
		font-size: ${rem(15)};
		line-height: ${rem(20)};
		letter-spacing: ${rem(0.38)};
		text-transform: 'uppercase';
		font-family: ${({ theme }) => theme.typography.fonts.tertiary};
		font-weight: 700;
		opacity: 0.5;
	}
	::-ms-input-placeholder {
		text-transform: uppercase;
		color: ${({ theme, color }) => color || theme.colors.white};
		font-size: ${rem(15)};
		line-height: ${rem(20)};
		letter-spacing: ${rem(0.38)};
		text-transform: 'uppercase';
		font-family: ${({ theme }) => theme.typography.fonts.tertiary};
		font-weight: 700;
		opacity: 0.5;
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		margin-bottom: 0;
		margin-right: ${({ theme }) => rem(theme.space[4])};
	}
`;

export const StyledMenuItem = styled('button')`
	padding-top: ${({ theme }) => rem(theme.space[1])};
	padding-bottom: ${({ theme, pb }) => pb || rem(theme.space[1])};
	&:not(:last-of-type) {
		margin-right: ${({ theme }) => rem(theme.space[5])};
	}
`;

export const MenuButton = styled(StyledMenuItem)`
	border: 2px solid ${({ theme, color }) => color || theme.colors.orange};
	border-radius: ${({ theme }) => rem(theme.space.xl)};
	padding: ${({ theme }) => `${rem(theme.space.md)} ${rem(theme.space.lg)}`};
	& div {
		text-align: center;
	}
`;

export const SubmitButton = styled(MenuButton)`
	background: transparent;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		flex: 0.5;
	}
`;
