import { AnimatePresence } from 'framer-motion';
import { rem } from 'polished';
import React from 'react';
import { Waypoint } from 'react-waypoint';

import {
	ContentContainer,
	ImgContent,
	ImgContentReverse,
	MainImage,
	SECTION_TITLE_MARGIN_BOTTOM,
	TextContent,
	TextContentReverse,
	ValueImageContainer,
	ValueSectionContainer,
	ValueSectionWrapper,
} from '../../../common/sections';

import { CheersImg1, CheersImg2 } from './styled';

export const IntegrateSection = ({
	anchorRef = null,
	containerRef = null,
	onEnter = () => {},
	topOffset = '0px',
	bottomOffset = '0px',
	children = null,
	debug = false,
	title = '',
	body = '',
	src = '/elements/trilogy-screen.png',
	...rest
}) => {
	return (
		<ValueSectionWrapper ref={containerRef}>
			<ValueSectionContainer {...rest} position="relative" flexDirection="column">
				<ContentContainer
					initial="hidden"
					whileInView="visible"
					viewport={{ once: false }}
					transition={{ duration: 1.5 }}
					variants={{
						visible: { opacity: 1 },
						hidden: { opacity: 0 },
					}}
				>
					<AnimatePresence>
						<>
							<TextContentReverse mb={SECTION_TITLE_MARGIN_BOTTOM}>
								<h3 className="mb-5">{title}</h3>
								<p>{body}</p>
							</TextContentReverse>
							<ImgContentReverse justifyContent={['center', 'center', 'flex-end']}>
								<ValueImageContainer>
									<MainImage className="hidden" src={src} alt="" ref={anchorRef} />
									<CheersImg1
										width={[140, 220, 200]}
										src="/wp-content/themes/theme-kit/images/swoop/courses/courses-putting.png"
									/>
									<CheersImg2
										width={[120, 200]}
										src="/wp-content/themes/theme-kit/images/swoop/courses/courses-golf-carts.png"
									/>
								</ValueImageContainer>
							</ImgContentReverse>
						</>
					</AnimatePresence>
				</ContentContainer>
				<Waypoint
					onEnter={onEnter}
					debug={debug}
					topOffset={topOffset}
					bottomOffset={bottomOffset}
				/>
			</ValueSectionContainer>
			{children}
		</ValueSectionWrapper>
	);
};
