const dummyCards = [
	{
		imgSrc: '/wp-content/themes/theme-kit/images/swoop/brand/brand-sand-trap.jpeg',
	},
	{
		imgSrc: '/wp-content/themes/theme-kit/images/swoop/brand/brand-aperol.jpeg',
	},
	{
		imgSrc: '/wp-content/themes/theme-kit/images/swoop/brand/brand-bag.jpeg',
	},
	{
		imgSrc: '/wp-content/themes/theme-kit/images/swoop/brand/brand-catch.png',
	},
	{
		imgSrc: '/wp-content/themes/theme-kit/images/swoop/brand/brand-pose.png',
	},
	{
		imgSrc: '/wp-content/themes/theme-kit/images/swoop/brand/brand-cocktail.png',
	},
];

export default dummyCards;
