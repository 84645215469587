import styled from 'styled-components';
import { rem } from 'polished';
import { Flex } from 'rebass/styled-components';
import { PhotoCarousel } from '../carousel/styled';

export const Block = styled('div')`
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-right: auto !important;
	margin-left: auto !important;
	@media (min-width: 1024px) {
		max-width: 980px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;

export const AmassadorsCarouselWrapper = styled(Block)`
	padding-left: 0;
	padding-right: 0;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		padding-left: ${rem(40)};
	}
`;

export const Card = styled(Flex)`
	flex: 0 0 100%;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		flex: 0 0 75%;
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		flex: 0 0 65%;
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
		flex: 0 0 50%;
	}
`;

export const EmblaParent = styled('div')``;

export const EmblaChild = styled(PhotoCarousel)``;

export const SocialsContainer = styled(Flex)`
	align-items: flex-end;
	& > :not(last-child) {
		margin-right: ${rem(14)};
	}
`;

export const CardImageWrapper = styled('div')`
	max-width: ${rem(190)};
	max-height: ${rem(400)};
	width: 100%;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
`;

export const Tag = styled('small')`
	font-size: ${rem(14)};
	font-weight: 700;
	color: ${({ theme }) => theme.colors.orange};
	text-transform: uppercase;
`;
export const Comment = styled('small')`
	font-size: ${rem(14)};
	font-weight: 700;
	color: ${({ theme }) => theme.colors.orange};
	text-transform: uppercase;
`;

export const ControlWrapper = styled(Flex)``;
