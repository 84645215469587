import { rem } from 'polished';
import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';

export const Section = styled(Flex)`
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-right: auto !important;
	margin-left: auto !important;
	overflow: visible;
	position: relative;

	@media (min-width: 1024px) {
		max-width: 980px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;

export const InstaGrid = styled('div')`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(${rem(130)}, 1fr));
	gap: 1rem;
	width: 100%;
	& > div:before {
		content: '';
		display: block;
		padding-top: 100%;
		grid-area: 1 / 1 / 2 / 2;
	}
	div {
		position: relative;
	}
	& > div img {
		width: 100%;
		grid-area: 1 / 1 / 2 / 2;
		object-fit: cover;
		object-position: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
`;

export const GridItem = styled('div')`
	display: grid;
`;

export const LeftCircle = styled('div')`
	position: absolute;
	left: -10%;
	top: -5%;
	height: ${rem(210)};
	width: ${rem(210)};
	border: 0.5px solid ${({ theme }) => theme.colors.calcite};
	border-radius: 50%;
	display: none;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		display: block;
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		left: -20%;
	}
`;

export const RightCircle = styled('div')`
	display: none;
	position: absolute;
	right: -42%;
	top: -10%;
	height: ${rem(610)};
	width: ${rem(610)};
	border: 0.5px solid ${({ theme }) => theme.colors.calcite};
	border-radius: 50%;
	z-index: -1;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		display: block;
	}
`;
