// import ArrowButton from 'components/common/icons/arrow-down';

import { rem } from 'polished';
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import {
	bottom,
	color,
	compose,
	layout,
	left,
	position,
	right,
	space,
	top,
	variant,
	system,
	zIndex,
} from 'styled-system';
import { variants } from '../common/text';

import Hourglass from '../icons/search';

export const StyledAccordion = styled(Accordion)`
	display: flex;
	flex-direction: column;

	${({ columns, theme }) =>
		columns === 2 &&
		`
		@media screen and (min-width: ${theme.breakpoints[1]}) {
			flex-direction: row;
		}
	`}

	[aria-expanded='true'] {
		& > svg {
			transform: rotate(180deg);
		}
	}
`;

export const StyledAccordionRowContainer = styled('div')`
	width: 100%;

	${({ columns, theme }) =>
		columns === 2 &&
		`
		@media screen and (min-width: ${theme.breakpoints[1]}) {
			width: 50%;
			&:first-of-type {
				margin-right: 40px;
			}
			&:last-of-type {
				margin-left: 40px;
			}
		}
	`}
`;
export const StyledAccordionItem = styled(AccordionItem)`
	flex: 0 1 100%;
  text-align: left;
	margin-bottom: ${rem(44)};
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		flex: 0 1 calc(50% - 40px);
	}
`;
export const StyledAccordionHeading = styled(AccordionItemHeading)`
	display: flex;
`;
export const StyledAccordionBtn = styled(AccordionItemButton)`
	display: flex;
	flex: 1;
    text-align: left;
	justify-content: space-between;
	padding-bottom: ${({ theme }) => rem(theme.space[5])};
	border-bottom: 2px solid ${({ theme }) => theme.colors.orange};
`;
export const StyledAccordionPanel = styled(AccordionItemPanel)``;

export const BoxWithPosition = styled(Box)(position, zIndex, top, right, bottom, left);

export const SearchWrapper = styled(BoxWithPosition)`
	box-sizing: border-box;
`;

export const StyledHourglass = styled(Hourglass)(
	position,
	zIndex,
	top,
	right,
	bottom,
	left,
	system({
		transform: true,
	}),
	space
);

export const ExtendendInput = styled('input')(space);

export const Search = styled(ExtendendInput)`
	background: transparent;
	border: 1px solid ${({ theme }) => theme.colors.white};
	border-radius: 24px;
	box-sizing: border-box;
	width: 100%;
	::placeholder {
		color: white;
	}
	${variant({ variants })}
	${compose(color, layout)}
`;
