import { rem } from 'polished';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SandwichImg = styled(motion.img)`
	position: absolute;
	max-width: ${rem(120)};
	top: ${rem(-40)};
	right: ${rem(-40)};
	z-index: 0;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		max-width: ${rem(200)};
		top: ${rem(-80)};
		right: ${rem(-160)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		max-width: ${rem(200)};
		top: ${rem(-40)};
		right: ${rem(0)};
	}
`;

export const ModeloImg = styled(motion.img)`
	position: absolute;
	max-height: ${rem(230)};
	left: ${rem(-40)};
	bottom: ${rem(-60)};
	z-index: 5;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
		max-height: ${rem(360)};
		left: ${rem(-120)};
		bottom: ${rem(-130)};
	}
	@media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		max-height: ${rem(360)};
		left: ${rem(0)};
		bottom: ${rem(100)};
	}
`;
